.c-switch-slider {
  &:before {
    background-color: #636363;
    border: 1px solid #b5b5b5;
  }

  border-color: #b5b5b5;
}

.modal-lg {
  max-width: 500px;
}

.text-foo {
  color: #00c3e2;
}

.btn-promo {
  background-color: #00c3e2;
  color: #fff;
}

.modal-backdrop.show {
  opacity: 0.9;
  background: linear-gradient(to top right,#5139cf,#46baca);
}

.footer-button {
  &:hover {
    color: white;
    background: #0694ab;

  }
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 65px;
  background: #00c3e2;
  border-radius: 0;
  color: white;
  font-family: sans-serif;
  font-size: 18px;
  text-decoration: none;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

footer {
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.carousel-indicators {
  display: none;
  bottom: -30px;

  li {
    background-color: #00c3e2;
  }
}