.table-spinner-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.spinner-child-xxl div {
  width: 6rem;
  height: 6rem;
  opacity: 0.5;
}

.spinner-child-xxl + svg,
.c-icon.c-icon-custom-size.text-danger.mb-2{
  display: none;
}

.spinner--transparent-medium {
  opacity: 0.5;
}