.text-hint {
  &--light {
    border-color: #969696;
  }
  &--lighter {
    border-color: #e0e0e0;
  }
  border-bottom: 1px dashed #222222;
  text-decoration: none;
  cursor: help;
}

.display-5 {
  font-size: 1.5rem;
  font-weight: 300;
  line-height: 1.2;
}