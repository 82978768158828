
.bg-loading {
  background-color: #ced2d8 !important;
}

.text-loading {
  color: #ced2d8 !important;
}

.bg-improving {
  background-color: #39be58 !important;
}

.text-improving {
  color: #39be58 !important;
}

.bg-extreme-dropping {
  background-color: #e55353 !important;
}

.text-extreme-dropping {
  color: #e55353 !important;
}

.bg-dropping {
  background-color: #f9b115 !important;
}

.text-dropping {
  color: #f9b115 !important;
}

.bg-no-conversions {
  background-color: #afafb1 !important;
}

.text-no-conversions {
  color: #afafb1 !important;
}

