.footer-standalone {
  position: fixed;
  bottom: 0;
  width: 100%;
  line-height: 30px;
  background-color: #f5f5f5;
  border-top: 3px solid #e0e0e0;
}

.card-standalone {
  max-width: 450px;
  margin-bottom: 7rem;
}

.card-standalone--large {
  max-width: 700px;
}

.logo-standalone {
  margin-bottom: 0;
  margin-top: 2rem;
}


@media (min-width: 768px) {
  .footer-standalone {
    line-height: 60px;
    border: none
  }

  .logo-standalone {
    margin-bottom: 3rem;
    margin-top: 4rem;
  }
}

@media (max-width: 768px) {
  .footer-standalone div {
    flex-flow: column-reverse;
  }
}