.product-title-cell {
  display: flex;

  &__image {
    height: 100px;
    width: 100px;
    background-color: #fff;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    border: 1px solid grey;
    margin-right: 2rem;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 100px;
  }
}

@media (min-width: 768px) {
  .Toastify__toast-container--top-right {
    margin-top: 54px;
    width: 400px !important;
  }
}

.thead-dark th {
  border: none !important;
}

@media (min-width: 1800px) {
  .table-xs {
    font-size: 13px !important;

    th {
      padding-bottom: 2rem !important;
    }

    th svg {
      bottom: 0;
    }
  }
}

@media (max-width: 1799px) {
  .table-xs {
    font-size: 11px !important;

    th {
      padding-bottom: 1.5rem !important;
    }

    th svg {
      bottom: 0;
    }
  }
}

.table-xs {

  th {
    border-top: none;
    padding-right: 0 !important;
    text-align: center;
    //vertical-align: bottom !important;
  }

  td {
    font-weight: 500 ;
  }

  th svg {
    top: auto;
    left: calc(50% - 7px);
  }

  th,
  td {
    padding: .23rem;
  }
}

.card-body--slim {
  padding: 0 0.5rem;
}

.c-sidebar .c-sidebar-nav-link.c-active {
  background-color: #31a8e1 !important;
}

.c-sidebar .c-sidebar-nav-link:hover {
  background-color: #186286;
}